<template>
        <v-dialog
          v-model="show"
          width="unset"
          v-on:click:outside="cancelMethod"
        >
          <v-card>
            <v-toolbar
                color="primary"
                dark
                class="text-h5"
            >Orari di {{user.username}}
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-card flat height="100%" class="d-flex flex-column mt-2">
                      <v-list two-lines v-if="displayUserWorkSchedules.length > 0 || creating">
                        <v-list-item-group
                          v-model="selectedUserWorkSchedule"
                          color="primary"
                        >
                          <!-- <p v-show="displayUserWorkSchedules.length == 0 && !creating" class="text--disabled text-center font-italic" >
                            no data
                          </p> -->
                          <div v-show="creating">
                            <v-list-item
                              class="text--disabled text-center font-italic" 
                              :value="displayUserWorkSchedules.length"
                            >
                              <v-list-item-content>
                                <v-list-item-title v-text="'creating...'"></v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-icon>
                                <v-icon>mdi-chevron-right</v-icon>
                              </v-list-item-icon>
                            </v-list-item>
                            <v-divider></v-divider>
                          </div>
                          <div 
                            v-for="(uws, i) in displayUserWorkSchedules"
                            :key="i"
                          >
                            <v-list-item
                              @click="editUserWorkSchedule(userWorkSchedules[i])"
                              :value="i"
                            >
                              <v-list-item-content>
                                <v-list-item-title v-text="uws.baseWorkScheduleDummyName || ''"></v-list-item-title>
                                <v-list-item-subtitle
                                  class="text--primary"
                                  v-text="uws.validityStartDateDisplayString + ' - ' + uws.validityStopDateDisplayString"
                                ></v-list-item-subtitle>

                                <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
                              </v-list-item-content>
                              <v-list-item-icon v-if="uws.active" align="center">
                                <v-icon>mdi-star-box</v-icon>
                              </v-list-item-icon>
                              <v-list-item-icon  align="center">
                                <v-icon>mdi-chevron-right</v-icon>
                              </v-list-item-icon>
                            </v-list-item>
                            <v-divider
                              v-if="i < displayUserWorkSchedules.length - 1"
                            ></v-divider>
                          </div>
                        </v-list-item-group>
                      </v-list>
                      
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        dark
                        @click="createUserWorkScheduleAction"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-plus
                        </v-icon>
                        Aggiungi Orario Pianificato
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-divider
                  inset
                  vertical
                  v-show="editing || creating"
                ></v-divider>
              <v-col v-show="editing || creating" cols="9">
              <v-card flat class="mt-8">
              <v-form ref="form">
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          :items="displayWorkScheduleDummies"
                          v-model="editedItem.baseWorkScheduleDummy"
                          label="Modello Orario"
                          prepend-icon="mdi-calendar-clock"
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col class="d-flex">
                        <v-btn
                          color="primary"
                          outlined
                          @click="applyWorkScheduleDummyTimes"
                          class="align-self-center"
                          :disabled="!(editedItem.baseWorkScheduleDummy && editedItem.baseWorkScheduleDummy.workScheduleDummyName)"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-clipboard-clock-outline
                          </v-icon>
                          Applica
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col>
                        <DateInput 
                          :label="'Inizio Validità'" 
                          :displayFormat="'d/M/yy'"
                          :maxWidth="'290px'"
                          :minWidth="'auto'"
                          :prependIcon="'mdi-calendar'"
                          :valueDateTime.sync="objectValidityStartDate"
                          :allowedDates="allowedUserWorkScheduleDates"
                          :max="objectValidityStopDate"
                          :rules="[rules.required]"
                        />
                      </v-col>
                      <v-col>
                        <DateInput 
                          :label="'Fine Validità'" 
                          :displayFormat="'d/M/yy'"
                          :maxWidth="'290px'"
                          :minWidth="'auto'"
                          :prependIcon="'mdi-calendar'"
                          :valueDateTime.sync="objectValidityStopDate"
                          :clearable="true"
                          :allowedDates="allowedUserWorkScheduleDates"
                          :min="objectValidityStartDate"
                          :disabled="!objectValidityStartDate"
                          :rules="requiredValidityStopDate ? [rules.required] : []"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-container>
                  <WeekTimesInput
                    v-model="editedItem.scheduledWeekWorkTimes"
                  />
                </v-container>
              </v-form>
                      
                    <v-card-actions>
                        <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    dark
                    @click="deleting = true"
                    v-show="editing"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-delete
                    </v-icon>
                    Elimina
                  </v-btn>
                  <!-- <v-btn
                    color="blue darken-1"
                    text
                    @click="closeEdit"
                  >
                    Cancel
                  </v-btn> -->
                  <v-btn
                    v-if="creating"
                    color="green accent-2"
                    class="px-4"
                    @click="createMethod"
                >
                    <v-icon left>
                        mdi-check
                    </v-icon>
                    Salva
                </v-btn>
                 <v-btn
                v-if="editing"
                color="amber lighten-1"
                class="px-4"
                @click="updateMethod"
            >
                <v-icon left>
                    mdi-check
                </v-icon>
                Modifica
            </v-btn>
                </v-card-actions>
              </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <ConfirmDialog
        :dialog="deleting" 
        :dialogText="'Eliminare definitivamente questo Orario Pianificato'"
        v-on:c-dialog-confirm="deleteUserWorkSchedule"
        v-on:c-dialog-cancel="deleting = false"
      />
    </v-card>
  </v-dialog>
</template>
<script>
export const WeekTimesInput = () => import('../../../global/WeekTimesInput.vue')
export const DateInput = () => import('../../../global/DateInput.vue')
export const ConfirmDialog = () => import('../../../global/ConfirmDialog.vue')

import { DateTime } from 'luxon'
import { mapState } from 'vuex'

export default {
    components: {
        DateInput,
        ConfirmDialog,
        WeekTimesInput
    },
    data: function() {
        return {
            headers: [
              { text: 'Base Work Schedule', align: 'start', value: 'baseWorkScheduleDummyName' },
              { text: '', value: 'actions', sortable: false, align: 'end' },
            ],
            editing: false,
            creating: false,
            deleting: false,
            dateFormat: 'd/M/yy',
            // validityStartDatemenu: false,
            // validityStopDatemenu: false,
            selectedUserWorkSchedule: null,
            editedIndex: -1,
            editedItem: {
              baseWorkScheduleDummy: {
                workScheduleDummyName: null,
              },
              validityStartDate: null,
              validityStopDate: null,
              scheduledWeekWorkTimes: {
                mondayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                tuesdayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                wednesdayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                thursdayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                fridayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                saturdayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                sundayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                }
              }
            },
            defaultItem: {
              baseWorkScheduleDummy: {
                workScheduleDummyName: null,
              },
              validityStartDate: null,
              validityStopDate: null,
              scheduledWeekWorkTimes: {
                mondayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                tuesdayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                wednesdayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                thursdayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                fridayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                saturdayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                },
                sundayWorkTimes: {
                    morningTimes: { clockInTime: null, clockOutTime: null, },
                    afternoonTimes: { clockInTime: null, clockOutTime: null, },
                }
              }
            },
            rules: {
              required: value => !!value || 'Required.',
              counter: value => value.length >= 8 || 'Min 8 characters',
            }
        }
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true
        },
        show: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        show: function(val) {
            this.resetStatus();
        }
    },
    methods: {
        cancelMethod () {
            this.$emit('close-dialog');
            this.resetStatus();
        },
        deleteUserWorkSchedule() {
            let userWorkScheduleValifityStartDate = this.userWorkSchedules[this.editedIndex].validityStartDate;
            let username = this.user.username;
            this.$emit('delete-user-work-schedule', username, userWorkScheduleValifityStartDate);
            this.deleting = false;
            this.resetStatus();
        },
        preselectWorkScheduleDummy(baseWorkScheduleDummyName) {
          let workScheduleDummy = this.workScheduleDummies.filter(wsd => wsd.workScheduleDummyName == baseWorkScheduleDummyName )[0];
          this.editedItem.baseWorkScheduleDummy = JSON.parse(JSON.stringify(workScheduleDummy))
        },
        applyWorkScheduleDummyTimes() {
          this.editedItem.scheduledWeekWorkTimes = JSON.parse(JSON.stringify(this.localizedDummyWeekWorkTimes));
        },
        editUserWorkSchedule(item) {
          this.editedItem = JSON.parse(JSON.stringify(item));
          this.editedIndex = this.userWorkSchedules.indexOf(item);
          if(item.baseWorkScheduleDummy)
            this.preselectWorkScheduleDummy(item.baseWorkScheduleDummy.workScheduleDummyName);
          this.editing = true;
          this.creating = false;
        },
        createUserWorkScheduleAction() {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.$refs.form.reset();
          this.creating = true;
          this.editing = false;
          this.editedIndex = -1;
          this.selectedUserWorkSchedule = this.displayUserWorkSchedules.length;
        },
        resetStatus() {
          this.creating = false;
          this.editing = false;
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
            this.selectedUserWorkSchedule = null;
          })
        },
        createMethod() {
            if (this.$refs.form.validate()) {
                let editedUserWorkSchedule = { ...this.editedItem };
                let editedBaseWorkScheduleDummyName = editedUserWorkSchedule.baseWorkScheduleDummy ? editedUserWorkSchedule.baseWorkScheduleDummy.workScheduleDummyName : null;
                let username = this.user.username;
                delete editedUserWorkSchedule.baseWorkScheduleDummy;
                this.$emit('create-user-work-schedule', editedUserWorkSchedule, username, editedBaseWorkScheduleDummyName);
                this.resetStatus();
            }
        },
        updateMethod() {
            if (this.$refs.form.validate()) {
                let editedUserWorkSchedule = { ...this.editedItem };
                let editedBaseWorkScheduleDummyName = editedUserWorkSchedule.baseWorkScheduleDummy ? editedUserWorkSchedule.baseWorkScheduleDummy.workScheduleDummyName : null;
                let username = this.user.username;
                delete editedUserWorkSchedule.baseWorkScheduleDummy;
                let userWorkScheduleValifityStartDate = this.userWorkSchedules[this.editedIndex].validityStartDate;
                this.$emit('update-user-work-schedule', editedUserWorkSchedule, username, editedBaseWorkScheduleDummyName, userWorkScheduleValifityStartDate);
                this.resetStatus();
            }
        },
    },
    computed: {
        ...mapState({
            workSchedules: state => state.dataStorage.userWorkSchedules,
            workScheduleDummies: state => state.dataStorage.workScheduleDummies,
        }),
        // showDialog: {
        //     get: function() {
        //         return this.dialog;
        //     },
        //     set: function(newVal) {
        //         if(!newVal) {
        //             this.closeEdit();
        //             this.$emit('close-dialog-user-work-schedule');
        //         }
        //     }
        // },
        localizedDummyWeekWorkTimes: function() {
          let ldwwt = {};
          for (var key in this.editedItem.baseWorkScheduleDummy.defaultWeekWorkTimes) {
              let temp = JSON.parse(JSON.stringify(this.editedItem.baseWorkScheduleDummy.defaultWeekWorkTimes[key]));
              if (temp.morningTimes.clockInTime && temp.morningTimes.clockOutTime) {
                temp.morningTimes.clockInTime = DateTime.fromISO(temp.morningTimes.clockInTime).toLocal().toISO();
                temp.morningTimes.clockOutTime = DateTime.fromISO(temp.morningTimes.clockOutTime).toLocal().toISO();
              }
              if (temp.afternoonTimes.clockInTime && temp.afternoonTimes.clockOutTime) {
                temp.afternoonTimes.clockInTime = DateTime.fromISO(temp.afternoonTimes.clockInTime).toLocal().toISO();
                temp.afternoonTimes.clockOutTime = DateTime.fromISO(temp.afternoonTimes.clockOutTime).toLocal().toISO();
              }
              ldwwt[key] = temp;
          }
          return ldwwt;
        },
        objectValidityStartDate: {
          get: function() {
            let validityStartDate = DateTime.fromISO(this.editedItem.validityStartDate);
            if (validityStartDate.invalid == null)
              return {day: validityStartDate.day, month: validityStartDate.month, year: validityStartDate.year}
            return null;
          },
          set: function(value) {
            let parsedDT = DateTime.fromObject(value);
            this.editedItem.validityStartDate = (value && parsedDT.invalid == null) ? parsedDT.toISODate() : null;
          }
        },
        objectValidityStopDate: {
          get: function() {
            let validityStopDate = DateTime.fromISO(this.editedItem.validityStopDate);
            if (validityStopDate.invalid == null)
              return {day: validityStopDate.day, month: validityStopDate.month, year: validityStopDate.year}
            return null;
          },
          set: function(value) {
            let parsedDT = DateTime.fromObject(value);
            this.editedItem.validityStopDate = (value && parsedDT.invalid == null) ? parsedDT.toISODate() : null;
          }
        },
        userWorkSchedules: function() {
            return this.workSchedules[this.user.username] || [];
        },
        workScheduleDummyNames() {
          return this.workScheduleDummies.map(workScheduleDummy => workScheduleDummy.workScheduleDummyName);
        },
        displayWorkScheduleDummies() {
          return this.workScheduleDummies.map(workScheduleDummy => {
            return {
              text: workScheduleDummy.workScheduleDummyName,
              value: workScheduleDummy,
            }
          });
        },
        displayUserWorkSchedules() {
          if(!this.show) {
            return [];
          }
          return this.userWorkSchedules.map(uws => {
            return {
              baseWorkScheduleDummyName: uws.baseWorkScheduleDummy ? uws.baseWorkScheduleDummy.workScheduleDummyName : '',
              scheduledWeekWorkTimes: JSON.parse(JSON.stringify(uws.scheduledWeekWorkTimes)),
              validityStartDate: uws.validityStartDate,
              validityStopDate: uws.validityStopDate,
              validityStartDateDisplayString: (uws.validityStartDate && DateTime.fromISO(uws.validityStartDate).invalid == null) ? DateTime.fromISO(uws.validityStartDate).toFormat('dd/MM/yy') : '',
              validityStopDateDisplayString: (uws.validityStopDate && DateTime.fromISO(uws.validityStopDate).invalid == null) ? DateTime.fromISO(uws.validityStopDate).toFormat('dd/MM/yy') : '',
              active: this.user.activeWorkSchedule ? (uws.validityStartDate == this.user.activeWorkSchedule.validityStartDate) : false
            }
          })
        },
        requiredValidityStopDate() {
            if(this.editedItem.validityStartDate == null)
                return false;
            let pSDate = DateTime.fromISO(this.editedItem.validityStartDate);
            return !!this.userWorkSchedules.find(function(uws) {
                let pValidityStartDate = DateTime.fromISO(uws.validityStartDate);
                if (pValidityStartDate.invalid != null)
                    return false;
                return pValidityStartDate > pSDate
            });
        },
        allowedUserWorkScheduleDates() {
          let max = null;
          let covered = [];
          this.userWorkSchedules.forEach((uws, index) => {
            if(index != this.editedIndex) {
              if (uws.validityStopDate == null) {
                max = uws.validityStartDate;
              } else {
                covered.push([uws.validityStartDate, uws.validityStopDate])
              }
            }
          });
          function foo(val) {
            covered.forEach(coveredRange => {
              if (coveredRange[0] <= val && coveredRange[1] >= val) {
                return false;
              }
            });
            if(val >= max)
              return false;
            return true;
          }
          return foo;
        },

    },
}
</script>
